import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import cheesesSubHeader from '../assets/images/blues-sub-header.jpg';
import SEO from '../components/SEO';

const CheesesHeader = styled.div`
  background: url(${cheesesSubHeader});
  background-size: cover;
  background-position: bottom center;
  min-height: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h3 {
    font-family: BrandFont;
    font-size: 3.5rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
    color: white;
  }
`;

const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 680px) {
    display: block;
    padding: 15px;
  }

  img {
    border-radius: 6px;
  }

  h2 {
    font-family: BrandFont;
    text-transform: uppercase;
    color: var(--blue);
  }

  button {
    text-transform: uppercase;
    font-size: 1.7rem;
    padding: 10px 20px 15px 20px;
  }

  button:disabled {
    cursor: arrow !important;
    opacity: 0.3;
  }

  p {
    font-size: 1.6rem;
  }

  .hidden {
    display: none;
  }

  span {
    color: var(--blue);
    width: 100px;
    display: inline-block;
  }

  .back-link {
    display: block;
    margin-top: 20px;
    background-color: skyblue;
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding-bottom: 13px;
    color: black;
  }
`;

const SingleProductTemplate = ({ pageContext }) => {
  const meltClass = pageContext.melt ? '' : 'hidden';

  return (
    <>
      <SEO title={pageContext.name} />
      <CheesesHeader>
        <div className="content-mw-1100">
          <h3>Our Cheeses</h3>
        </div>
      </CheesesHeader>

      <div className="content-mw-1100 ">
        <ProductDetails>
          <div>
            <img src={`/images/products/${pageContext.image}`} alt="" />
            <Link to="/cheeses" className="back-link">
              &laquo; Back to all cheeses
            </Link>
          </div>
          <div>
            <h2>{pageContext.name}</h2>
            <p>{pageContext.description}</p>
            <p>
              <span>Flavour:</span> {pageContext.flavour}
            </p>
            <p>
              <span>Texture:</span> {pageContext.texture}
            </p>
            <p>
              <span>Colour:</span> {pageContext.color}
            </p>
            <p>
              <span>Age profile:</span> {pageContext.ageProfile}
            </p>
            <p className={meltClass}>
              <span>Melt:</span> {pageContext.melt}
            </p>
            {/* <p>
              <span>Price per/kg:</span> &euro;
              {parseInt(pageContext.pricePerKg / 100).toFixed(2)}
            </p> */}
            <button
              disabled="disabled"
              className="snipcart-add-item"
              data-item-id={pageContext.slug}
              data-item-price="19.99"
              data-item-url={`/cheeses/${pageContext.slug}`}
              data-item-name={pageContext.name}
              data-item-image={`/images/products/${pageContext.image}`}
              data-item-file-guid={pageContext.sku}
            >
              Add to cart
            </button>
            <p
              className="online-sales"
              style={{
                border: 'dashed 1px #cccccc',
                backgroundColor: '#bde5f5',
                marginRight: '20px',
                padding: '15px',
              }}
            >
              Our entire range will be available to purchase online in 2021.
              They can also be purchased from:
              <ul>
                <li>The Loose Canon, Drury St, Dublin</li>
                <li>Toonsbridge Dairy, South Great Georges St, Dublin</li>
                <li>
                  The Flying Cheese Brigade, Ennis Farmers Market (every Friday)
                </li>
                <li>The Milk Market Limerick (every Saturday)</li>
                <li>L Art de la Fromagerie, 32006 Marseille</li>
                <li>Mikes Fancy Cheese, Donegal St, Belfast</li>
                <li>Naomh Olaf Farmers Market Sandyford (every Friday)</li>
                <li>Clonmel Farmers market (every Friday)</li>
                <li>Kilkenny Farmers market (every Thursday)</li>
                <li>
                  The Blues Creamery E34 AX57 by arrangement for collection
                </li>
              </ul>
            </p>
          </div>
        </ProductDetails>
      </div>
    </>
  );
};

export default SingleProductTemplate;
